import React from 'react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/layout'
import { Button } from '../components/button'
import { ReadLink } from '../components/link'
import { Emoji } from '../components/emoji'
import { absoluteUrl } from '../utils/url'
import { email } from '../metadata'

const title = 'Kontakt'
const description = 'Potřebuješ korekturu, stylistiku, článek nebo se mě chceš na něco zeptat? Tak směle do toho, ráda si na tebe udělám čas.' // prettier-ignore

export default class KontaktPage extends React.Component {
  state = {
    done: false,
    error: false,
    loading: false,
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(new FormData(event.target)),
      })
      this.setState({ done: true })
    } catch (err) {
      console.error(err)
      this.setState({ error: true })
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { props, state } = this
    const { location } = props
    const { done, loading, error } = state
    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:url" content={absoluteUrl(location.pathname)} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        {done ? (
          <Article className="no-offset">
            <h1>Úspěšně odesláno!</h1>
            <p>
              Díky za zprávu, právě se doručuje...{' '}
              <Emoji symbol="👍" label="Thumbs up" />
            </p>
            <video
              src="https://media.giphy.com/media/HRydU7WlpaBby/giphy.mp4"
              type="video/mp4"
              width={360}
              height={354}
              autoPlay
              loop
              muted
            />
            <p>
              Co takhle si v mezičase přečíst nějaký článek?{' '}
              <Emoji symbol="😊" label="Smile" />
            </p>
            <ReadLink to="/">
              <Emoji symbol="←" label="back" /> Zpět na blog
            </ReadLink>
          </Article>
        ) : (
          <Article className="no-offset">
            <h1>Chceš se mě na něco zeptat?</h1>
            <p>Tak směle do toho, ráda si na tebe udělám čas.</p>
            <Form
              name="contact"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="love"
              onSubmit={this.handleSubmit}>
              {error ? (
                <p className="error-box">
                  Ups, něco se pokazilo...{' '}
                  <Emoji symbol="🤔" label="Thinking" />
                  <br />
                  Zkus to prosím znovu nebo mi napiš na&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>.
                </p>
              ) : (
                <br />
              )}
              <label className="form-name">
                <input
                  disabled={loading}
                  type="hidden"
                  name="form-name"
                  value="contact"
                  autoComplete="off"
                  tabIndex="-1"
                  aria-required="false"
                />
              </label>
              <label className="love">
                Laska
                <input
                  disabled={loading}
                  type="text"
                  name="love"
                  autoComplete="off"
                  tabIndex="-1"
                  aria-required="false"
                />
              </label>
              <label className="name">
                Tvé jméno
                <input disabled={loading} type="text" name="name" />
              </label>
              <label className="email">
                Tvůj e-mail
                <input
                  disabled={loading}
                  type="email"
                  name="email"
                  placeholder="@"
                  aria-required="true"
                  required
                />
              </label>
              <label className="message">
                Zpráva pro mě
                <textarea
                  name="message"
                  disabled={loading}
                  rows="6"
                  aria-required="true"
                  required
                />
              </label>
              <label className="newsletter">
                <input disabled={loading} type="checkbox" name="newsletter" />
                <p>Nechci posílat čerstvé novinky na můj e-mail.</p>
              </label>
              <Button type="submit" disabled={loading}>
                {loading ? (
                  'Odesílám...'
                ) : (
                  <span>
                    ODESLAT ZPRÁVU <Emoji symbol="👌" label="OK hand" />
                  </span>
                )}
              </Button>
              <p className="gdpr">
                Tvé osobní údaje jsou u mě v bezpečí, použiji je jen k vyřešení
                tvého dotazu. Mrkni na mé{' '}
                <a
                  target="_blank"
                  href="/ochrana-osobnich-udaju"
                  rel="noopener noreferrer">
                  zásady zpracování osobních údajů
                </a>
                .
              </p>
            </Form>
          </Article>
        )}
      </Layout>
    )
  }
}

/* Styles */
const Article = styled.article`
  video {
    margin-bottom: 1.25rem;
  }
`

const Form = styled.form`
  max-width: 365px;
  label {
    display: flex;
    flex-flow: column;
  }
  label + label,
  button {
    margin-bottom: 1rem;
  }
  input,
  textarea {
    padding: 0.4rem 0.8rem;
    margin-top: 0.25rem;
    border: 1px solid #ccc;
    border-radius: 3px;
    resize: vertical;
    min-height: 2.5rem;
    max-height: 25rem;
  }
  .form-name,
  .love {
    display: none;
  }
  input[type='checkbox'],
  input[type='radio'] {
    display: inline;
    margin-right: 7px;
  }
  .newsletter {
    display: flex;
    flex-direction: row;
    align-items: center;
    input,
    p {
    }
    input {
      margin-top: 0;
    }
    p {
      font-size: 12px;
      margin: 0;
    }
  }
  button {
    width: 100%;
  }
  input:disabled,
  textarea:disabled,
  button:disabled {
    opacity: 0.7;
    &:hover {
      cursor: wait;
    }
  }
  .gdpr {
    font-size: 12px;
  }
  .error-box {
    text-align: center;
    border: 1px solid #f7a046;
    color: #f7a046;
    padding: 1rem;
    hyphens: none;
  }
`
