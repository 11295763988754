import React from 'react'
import { css } from '@emotion/react'

export function Button(props) {
  return <button css={buttonCss} {...props} />
}

const buttonCss = css`
  border-radius: 3px;
  color: #fff;
  border: 1px solid #5d93ff;
  background-color: #5d93ff;
  padding: 0.4rem 0.8rem;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  :hover {
    cursor: pointer;
    border-color: #f7a046;
    background-color: #f7a046;
  }
  :disabled {
    cursor: not-allowed;
    border-color: #f7a046;
    background-color: #f7a046;
  }
`
