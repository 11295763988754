import React from 'react'

export function Emoji({ children, symbol, label = '', ...rest }) {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label}
      aria-hidden={label ? 'false' : 'true'}
      {...rest}>
      {symbol || children}
    </span>
  )
}
