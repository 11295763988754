import React from 'react'
import { Helmet } from 'react-helmet'

import { title, description } from '../metadata'
import { BaseLayout } from './base-layout'
import { Sidebar } from './sidebar'
import { absoluteUrl } from '../utils/url'

export function Layout({ children }) {
  return (
    <BaseLayout sidebar={<Sidebar />}>
      <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
        <html lang="cs" />
        <meta name="author" content="https://www.svager.cz" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={absoluteUrl(`/media/korektury-stylistika-copywriting.png`)}
        />
        <meta property="og:image:width" content="1120" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      {children}
    </BaseLayout>
  )
}
